/* eslint-disable no-console */
/* eslint-disable object-curly-newline */
import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { AvForm } from "availity-reactstrap-validation";
import { updateMasterIbAgreement } from "store/actions";

import {
  Row, Col, Button,
  Modal, ModalBody, ModalHeader,
} from "reactstrap";

import validatePositiveInputs from "helpers/validatePositiveInputs";
import AvFieldSelect from "components/Common/AvFieldSelect";

const getAccountTypeName = (accountTypeId, accountTypes) => {
  return accountTypes?.find((type) => type._id === accountTypeId)?.title;
};

const EditMasterIbModal = ({ show, toggle, accountTypes, customerId, products, agreement, markups }) => {
  const customerAgreement = agreement.members && agreement.members.find((mem) => mem.customerId._id === customerId);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { submitting } = useSelector((state) => state.ibAgreements);

  // State to manage form values
  const [formValues, setFormValues] = useState({
    title: agreement.title || "",
    values: [],
  });

  // Initialize form values when the modal is shown
  useEffect(() => {
    if (show && customerAgreement) {
      const initialValues = customerAgreement.values.map((agr) => ({
        accountTypeId: agr.accountTypeId,
        markup: agr.markup,
        products: Object.keys(agr.products).reduce((acc, prod) => {
          acc[prod] = {
            rebate: agr.products[prod]?.rebate || 0,
            commission: agr.products[prod]?.commission || 0,
          };
          return acc;
        }, {}),
      }));
      setFormValues({
        title: agreement.title,
        values: initialValues,
      });
    }
  }, [show, customerAgreement]);

  // Handle input change
  const handleInputChange = (agrIdx, field, value, prod = null) => {
    setFormValues((prev) => {
      const updatedValues = [...prev.values];
      if (prod) {
        updatedValues[agrIdx].products[prod][field] = value;
      } else {
        updatedValues[agrIdx][field] = value;
      }
      return {
        ...prev,
        values: updatedValues,
      };
    });
  };

  // Handle form submit
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      updateMasterIbAgreement({
        id: agreement._id,
        customerId,
        title: formValues.title,
        values: formValues.values,
      })
    );
  };

  // Close modal when submit complete
  useEffect(() => {
    if (!submitting && show) {
      toggle();
    }
  }, [submitting]);

  if (!show) return null;

  return (
    <Modal isOpen={show} toggle={toggle} centered={true} scrollable={true}>
      <ModalHeader toggle={toggle} tag="h4">
        {t("Edit Master IB Agreement")}
      </ModalHeader>
      <ModalBody>
        <AvForm onValidSubmit={handleSubmit}>
          <div className="form-group">
            <label>{t("Agreement name")}</label>
            <input
              name="title"
              type="text"
              className="form-control mb-3"
              value={formValues.title}
              onChange={(e) =>
                setFormValues((prev) => ({
                  ...prev,
                  title: e.target.value,
                }))
              }
              required
            />
          </div>

          {formValues.values.map((agr, agrIdx) => (
            <div key={agrIdx}>
              <h6>{getAccountTypeName(agr.accountTypeId, accountTypes)}</h6>
              <Row className="justify-content-start">
                <Col md="3">{t("Type")}</Col>
                <Col>{t("Total Rebate")}</Col>
                <Col>{t("Total Commission")}</Col>
              </Row>

              {products.map((prod, prodIdx) => (
                <Row key={prodIdx} className="my-1 align-items-center">
                  <Col md="2">{prod}</Col>
                  <Col>
                    <input
                      name={`values[${agrIdx}].products.${prod}.rebate`}
                      type="number"
                      min="0"
                      className="form-control"
                      value={agr.products[prod]?.rebate || "0"}
                      onChange={(e) =>
                        handleInputChange(agrIdx, "rebate", e.target.value, prod)
                      }
                      onKeyPress={(e) => validatePositiveInputs(e)}
                      required
                    />
                  </Col>
                  <Col>
                    <input
                      name={`values[${agrIdx}].products.${prod}.commission`}
                      type="number"
                      min="0"
                      className="form-control mt-3"
                      value={agr.products[prod]?.commission || "0"}
                      onChange={(e) =>
                        handleInputChange(agrIdx, "commission", e.target.value, prod)
                      }
                      onKeyPress={(e) => validatePositiveInputs(e)}
                      required
                    />
                  </Col>
                </Row>
              ))}

              <Row className="my-1 align-items-center">
                <Col md="2">{t("Markup")}</Col>
                <Col>
                  <AvFieldSelect
                    name={`values[${agrIdx}].markup`}
                    value={agr.markup}
                    options={(markups || []).map((obj) => ({
                      label: `${obj}`,
                      value: obj,
                    }))}
                    onChange={(value) =>
                      handleInputChange(agrIdx, "markup", value)
                    }
                  />
                </Col>
              </Row>
              <hr className="my-3" />
            </div>
          ))}

          <Button type="submit" disabled={submitting}>
            {t("Submit")}
          </Button>
        </AvForm>
      </ModalBody>
    </Modal>
  );
};

export default memo(EditMasterIbModal);